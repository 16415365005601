import React from "react"
import classNames from "classnames"
import { SectionSplitProps } from "utils/SectionProps"
import SectionHeader from "components/layout/partials/SectionHeader"
import Image from "components/elements/Image"

import animx_image from "assets/images/graph_editor.png"
import shading_image from "assets/images/shading.png"
import usd_editing_image from "assets/images/usd_editing.png"

import LayoutDefault from "layouts/LayoutDefault"
import SolutionFeaturesTiles from "components/layout/en/SolutionFeaturesTiles"
import Cta from "components/layout/en/Cta"
import ModalVideo from "components/elements/ModalVideo"
import ButtonGroup from "components/elements/ButtonGroup"
import RequestAccessButton from "components/layout/RequestAccessButton"


const propTypes = {
  ...SectionSplitProps.types,
}

const defaultProps = {
  ...SectionSplitProps.defaults,
}

const Index = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  )

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  )

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  )

  const sectionHeader = {
    title: [
      <>
       Wizart <span className="text-color-primary">DCC</span> Platform <br/>
        for Mobile <span className="text-color-primary">AR</span>
      </>
    ],
    paragraph:
      <> Effective <span className="text-color-primary">toolbox</span> for your AR content </>
  }

  return (
    <LayoutDefault {...props}>
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader
              data={sectionHeader}
              className="center-content"
              style={{
                paddingBottom: 32,
              }}
            />
            <div className="center-content">
              <ButtonGroup>
                <RequestAccessButton name={"Request Access"} />
              </ButtonGroup>
            </div>
            <div className={splitClasses}>
              <SolutionFeaturesTiles/>
              <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    Open Source Animation
                  </div> */}
                  <h3 className="mt-0 mb-12">USD</h3>
                  <p className="m-0">
                    Our app is direct <a href="https://graphics.pixar.com/usd">Pixar's Universal Scene Description (USD) </a> editor, that gives you future-proof 3d scene data format for all your
                     that compatible with many third-party applications and runtimes like <a href="https://unity.com/">Unity</a> or <a href="https://www.unrealengine.com/">Unreal Engine</a>. 
                     That also means you can directly inspect and export for Apple's <a href="https://developer.apple.com/documentation/arkit/usdz_schemas_for_ar">USDZ</a> format.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <ModalVideo
                    videoId="-UeXXgjN7yY"
                    width={528}
                    height={396}
                    thumbnailSrc = {usd_editing_image}
                  />
                </div>
              </div>

              <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-right"
                  data-reveal-container=".split-item"
                >
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    In development
                  </div>
                  <h3 className="mt-0 mb-12">GLTF FBX Support</h3>
                  <p className="m-0">
                    Would it be awesome if you can import/export GLTF, FBX, that would be consumable for other Mobile AR runtimes as well?
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  {/* <Image
                    src={wgen_image}
                    alt="Features split 02"
                    width={528}
                    height={396}
                  /> */}
                </div>
              </div>
              <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    Open Source Animation
                  </div> */}
                  <h3 className="mt-0 mb-12">Shading</h3>
                  <p className="m-0">
                    Node Editor allows you to modify any USDShade shading networks for any target renderer. This way you can interactively author materials for you digital avatar assets.
                    To make it more future-proof, we also working on adding support for <a href="https://www.materialx.org/">MaterialX</a>.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <ModalVideo
                    videoId="qDqClwhsQU4"
                    width={528}
                    height={396}
                    thumbnailSrc={shading_image}
                  />
                </div>
              </div>
              
              <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    Open Source Animation
                  </div> */}
                  <h3 className="mt-0 mb-12">AnimX</h3>
                  <p className="m-0">
                    You can author digital avatars animation using <a href="https://github.com/Autodesk/animx">AnimX</a> compatible animation curves animation engine. 
                    Using Graph Editor, so you can edit animation right inside the app.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                   <ModalVideo
                    videoId="Kh_Epd-UPDU"
                    width={528}
                    height={396}
                    thumbnailSrc={animx_image}
                  />
                </div>
              </div>              
            </div>
          </div>
        </div>
      </section>
      <Cta split topDivider />
    </LayoutDefault>
  )
}

Index.propTypes = propTypes
Index.defaultProps = defaultProps

export default Index